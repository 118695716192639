<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      :variant="alertVariant"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged">
      {{ alertMessage }}
    </b-alert>
  </div>
</template>

<script>
 // To use this component. Define the props in the parent. Define `showAlert` as `false`. When you wish to trigger
 // the alert redefine `showAlert === !showAlert`, which will allow it to toggle perpetually.
 // If `showAlert` is initially set to `true`, the page will render showing the alert
 // Auto dismisses after `dismissSecs` has expired
export default {
  name: 'Alert',
  props: {
    dismissSecs: {
      type: Number,
      default: 0
    },
    showAlert: {
      type: Boolean,
      default: false
    },
    alertMessage: {
      type: String,
      default: ''
    },
    alertVariant: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dismissCountDown: 0
    };
  },
  watch: {
    showAlert: function() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  created() {
    if (this.showAlert) {
      this.dismissCountDown = this.dismissSecs;
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  }
};
</script>
